.active-field{
  border:solid 1px #fff;
  border-radius:6px;
  min-height:30px;
}
.cardMediaBg {
  object-fit:fill!important;
  background: #8e9eab; /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #eef2f3, #8e9eab); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #eef2f3, #8e9eab); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
  .cardMediaBg:hover {
  border:1px solid #fff;
}

.contactNumber{
    font-size: 14px;
    font-family:
      "Inter",-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji";
    font-weight: 400;
    line-height: 1.4375em;
    color: #223354;
    box-sizing: border-box;
    padding: 10px;
}
.error{
  color: #FF1943;
  line-height: 1.66;
    text-align: left;
    margin-top: 3px;
}